import { Row, Worksheet } from "exceljs";
import { unflatten } from "flat";

const OA_FILE_NAME_HEADER = "#oa-filename";
const RECIPIENT_EMAIL_HEADER = "#recipient-email";
const META_HEADER_TO_KEY = {
  [OA_FILE_NAME_HEADER]: "oaFilename",
  [RECIPIENT_EMAIL_HEADER]: "recipientEmail",
} as const;
export const SUPPORTED_FORMAT_VERSIONS = ["v2", "v3"];

export interface ParsedDocumentRow {
  meta: { oaFilename: string; recipientEmail: string; recipientName?: string };
  rawDocument: Record<string, unknown>;
}
interface ParseExcelSheetResults {
  issuerId: string;
  docType: string;
  parsedDocumentRows: ParsedDocumentRow[];
}
export function parseExcelSheet(sheet: Worksheet): ParseExcelSheetResults {
  const version = sheet.getCell("A1").text;
  const issuerId = sheet.getCell("B1").text;
  const docType = sheet.getCell("C1").text;

  // validate sheet
  if (!SUPPORTED_FORMAT_VERSIONS.some((e) => e === version))
    throw Error(
      `Unsupported sheet version, only ${SUPPORTED_FORMAT_VERSIONS} is supported right now`
    );
  // throw error if falsey: undefined, null, blank string.
  if (!issuerId || !docType) throw Error(`issuerId or docType is missing`);

  if (sheet.getCell("A2").text !== OA_FILE_NAME_HEADER)
    throw Error(`Expected A2 to be ${OA_FILE_NAME_HEADER}`);

  if (sheet.getCell("B2").text !== RECIPIENT_EMAIL_HEADER)
    throw Error(`Expected B2 to be ${RECIPIENT_EMAIL_HEADER}`);

  const rowObjects = parseExcelSheetForObjects(sheet, 2);

  const parsedDocumentRows = rowObjects.map((rowObj) => {
    const meta: ParsedDocumentRow["meta"] = {} as ParsedDocumentRow["meta"];
    const rawDocument: Record<string, unknown> = {};
    const keys = Object.keys(rowObj);
    for (const key of keys) {
      const value = rowObj[key];

      // use name if possible for recipient name
      if (key === "name" && value) meta.recipientName = value;

      if (META_HEADER_TO_KEY[key as keyof typeof META_HEADER_TO_KEY]) {
        meta[META_HEADER_TO_KEY[key as keyof typeof META_HEADER_TO_KEY]] =
          value;
      } else {
        rawDocument[key] = value;
      }
    }

    return {
      meta,
      rawDocument: unflatten(rawDocument) as Record<string, unknown>,
    };
  });

  return {
    // TODO: Improve me soon!
    // Cher Shen: There is a problem in UAT becuase this is assuming the template name from the issuer name in the excel template. The way I set things up in STG is not using expected default values, hence the following ugry workaround
    issuerId: String(issuerId).replace("-stg", ""),
    docType: String(docType),
    parsedDocumentRows,
  };
}

function parseExcelSheetForObjects(
  sheet: Worksheet,
  headerRowIndex: number
): Record<string, string>[] {
  const headers = getHeadersFromRow(sheet.getRow(headerRowIndex));

  const rowObjects: Record<string, string>[] = [];
  // iterate through rows after the header row
  sheet.eachRow((row, rowNumber) => {
    if (rowNumber > headerRowIndex) {
      const rowObj: Record<string, string> = {};
      for (let i = 0; i < headers.length; i += 1) {
        const header = headers[i];
        const cell = row.getCell(i + 1);
        rowObj[header] =
          cell?.text !== undefined && cell?.text !== null
            ? String(cell?.text)
            : "";
      }
      rowObjects.push(rowObj);
    }
  });

  return rowObjects;
}

function getHeadersFromRow(headerRow: Row): string[] {
  const documentHeaders: string[] = [];
  let i = 1;
  while (headerRow.getCell(i).text) {
    documentHeaders.push(String(headerRow.getCell(i).text));
    i += 1;
  }

  return documentHeaders;
}
