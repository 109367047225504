import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Arrow } from "./arrow.component";

const NavButton = styled.button`
  display: flex;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const ErrorMsg = styled.p`
  position: absolute;
  width: 150px;
  left: -75px;
  text-align: center;
`;

interface SelectorProps {
  selected: number;
  upperLimit: number;
  onSelectionChange: (selected: number) => void;
}

const NumberInput = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  -moz-appearance: textfield; /* Firefox */
`;

function Selector(props: SelectorProps) {
  const [inputSelection, setInputSelection] = useState("1");
  const [status, setStatus] = useState<{ isInvalid: boolean; msg?: string }>({
    isInvalid: false,
  });

  const inputStyle = {
    width: `${inputSelection.length * 10}px`,
    minWidth: "10px",
    background: status.isInvalid ? "pink" : "white",
  };

  // primarily handle visual state of the input box, as opposed to the real selection state
  function handleInputSelectionChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.value === "") {
      setInputSelection(""); // blank input is allowed
      return;
    }
    setStatus({ isInvalid: false });
    const num = Number(event.target.value);
    /* guard clauses */
    if (!Number.isInteger(num)) {
      setStatus({ isInvalid: true, msg: "not a number" });
    }
    if (num <= 0 || num > props.upperLimit) {
      setStatus({
        isInvalid: true,
        msg: "has to be more than 0 and less than max num rows",
      });
    }
    setInputSelection(event.target.value);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (inputSelection === "") return;
    if (status.isInvalid) return;
    props.onSelectionChange(parseInt(inputSelection));
  }

  // When arrows are pressed, inform parent component about what is the next logical change in selection
  // it is up to them to decide if they want to finally update the change
  function iterateThruDocs(direction: "left" | "right") {
    const num = Number(inputSelection);
    if (!Number.isInteger(num)) return;

    if (num < 1 && direction === "right") {
      /* user is out of range, way below 0, e.g. -5. He intends to go right. just bump to 1 directly */
      props.onSelectionChange(1);
    } else if (num > props.upperLimit && direction === "left") {
      /* user is out of range, way above documents.length. He intends to go left. just let him jump to last document. */
      props.onSelectionChange(props.upperLimit);
    } else {
      /* user is within range */
      if (num <= 1 && direction === "left") return; // prevent user from going below lower bound
      if (num >= props.upperLimit && direction === "right") return; // prevent user from going above upper bound
      const newIndex = num + (direction === "right" ? 1 : -1);
      props.onSelectionChange(newIndex);
    }
  }

  // When parent updates their selection state and inject the value into our component
  // we validate it and show error if needed
  useEffect(() => {
    if (props.selected <= 0 || props.selected > props.upperLimit) {
      setStatus({
        isInvalid: true,
        msg: "has to be more than 0 and less than max num rows",
      });
    } else {
      setStatus({
        isInvalid: false,
      });
      setInputSelection(String(props.selected));
    }
  }, [props.selected, props.upperLimit]);

  return (
    <div
      style={{
        display: "flex",
        marginTop: "16px",
        marginBottom: "16px",
        justifyContent: "space-between",
      }}
    >
      <NavButton
        style={{ visibility: props.selected === 1 ? "hidden" : "visible" }}
        onClick={() => iterateThruDocs("left")}
      >
        <Arrow $rotation={135} />
        <span>Previous</span>
      </NavButton>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "8px", fontWeight: "bold" }}>Displaying</div>
        <form onSubmit={handleSubmit} style={{ position: "relative" }}>
          <NumberInput
            type="number"
            value={`${inputSelection}`}
            onChange={handleInputSelectionChange}
            style={inputStyle}
            min={1}
            max={props.upperLimit}
          ></NumberInput>
          <label>/ {props.upperLimit === 0 ? "-" : props.upperLimit}</label>
          <ErrorMsg>
            {status.isInvalid && status.msg ? status.msg : ""}
          </ErrorMsg>
        </form>
      </div>
      <NavButton
        style={{
          visibility:
            props.selected === props.upperLimit ? "hidden" : "visible",
        }}
        onClick={() => iterateThruDocs("right")}
      >
        <span>Next</span>
        <Arrow $rotation={-45} />
      </NavButton>
    </div>
  );
}

export default Selector;
