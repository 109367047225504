import { ParsedDocumentRow } from "./parse-xlsx";
import { OaDocument } from "./App";

export const urlMap: Record<string, string> = {
  "national-youth-council":
    "https://national-youth-council.renderer.oa.gov.sg/",
  "national-parks-board":
    "https://national-parks-board.renderer.oa.gov.sg/",
  "govtech-digital-academy":
    "https://govtech-digital-academy.renderer.oa.gov.sg/",
  "singapore-judicial-college":
    "https://singapore-judicial-college.renderer.oa.gov.sg/",
  "health-sciences-authority":
    "https://health-sciences-authority.renderer.oa.gov.sg/",
};

const docNameMap: Record<string, string> = {
  "national-youth-council": "nationalYouthCouncilBclsAed",
};

export function makeOaDocuments(
  issuerId: string,
  docType: string,
  rows: ParsedDocumentRow[]
): OaDocument[] {
  const $template = {
    name: `${issuerId}/${docType}`,
    type: "EMBEDDED_RENDERER",
    url: urlMap[issuerId],
  };

  const documents = rows.map((raw) => {
    return {
      name: docNameMap[issuerId],
      document: {
        $template,
        issuers: [{ name: "institute of blockchain" }],
        ...raw.rawDocument,
      },
    };
  });

  return documents;
}
