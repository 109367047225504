import styled from "styled-components";

export const Arrow = styled.div<{
  $rotation: number;
}>`
  cursor: pointer;
  width: 8px;
  height: 8px;
  border: solid black;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ $rotation }) => `rotate(${$rotation}deg)`};
  -webkit-transform: ${({ $rotation }) => `rotate(${$rotation}deg)`};
  &:hover {
    border: solid grey;
    border-width: 0 4px 4px 0;
  }
`;
