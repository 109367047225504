import React, { useState, useRef } from "react";
import { Workbook } from "exceljs";
import styled from "styled-components";
import { ParsedDocumentRow, parseExcelSheet } from "./parse-xlsx";

interface UploaderProps {
  onParseXlsx: (props: {
    parsedDocumentRows: ParsedDocumentRow[];
    filename: string;
    issuerId: string;
    docType: string;
  }) => void;
}

const Form = styled.form`
  height: 200px;
  width: 200px;
  max-width: 100%;
  text-align: center;
  position: relative;
`;

const UploadButton = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: sans-serif;
  background-color: transparent;
  &:hover {
    text-decoration-line: underline;
  }
`;

const UploadLabel = styled.label`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
`;

const DragFileDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

function Uploader(props: UploaderProps) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover")
      setDragActive(true);
    else if (event.type === "dragleave") setDragActive(false);
  };

  function handleFile(files: FileList) {
    const fileName = files[0].name;
    const fileExt = fileName.split(".").pop();
    if (fileExt !== "xlsx") {
      alert("not an excel file");
      return;
    }

    const wb = new Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(files[0]);
    reader.onload = () => {
      const buffer = reader.result as Buffer;

      if (!buffer) return;

      wb.xlsx
        .load(buffer)
        .then(async (workbook) => {
          if (!workbook.worksheets.length)
            throw new Error("No worksheets found in xlsx");

          const { issuerId, docType, parsedDocumentRows } = parseExcelSheet(
            workbook.worksheets[0]
          );

          props.onParseXlsx({
            parsedDocumentRows,
            filename: fileName,
            issuerId,
            docType,
          });
        })
        .catch((error) => {
          alert(
            error?.message ??
              "Please check the contents of your xlsx file and try uploading again!"
          );
        });
    };
  }

  // triggers when file is dropped
  const handleDrop = function (event: React.DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      console.log("no files");
      return;
    }

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFile(event.dataTransfer.files);
    }
  };

  function uploadFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || event.target.files.length === 0) {
      console.log("no files");
      return;
    }
    handleFile(event.target.files);
  }

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} onDragEnter={handleDrag}>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={uploadFile}
        value=""
        style={{ display: "none" }}
      />
      <UploadLabel
        htmlFor="input-file-upload"
        style={{ ...(dragActive && { backgroundColor: "white" }) }}
      >
        <div>
          <div>Drag and drop your xlsx file here or</div>
          <UploadButton onClick={onButtonClick}>
            Upload a xlsx file
          </UploadButton>
        </div>
      </UploadLabel>
      {dragActive && (
        <DragFileDiv
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></DragFileDiv>
      )}
    </Form>
  );
}

export default Uploader;
