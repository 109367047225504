import { paramCase } from "change-case";
import Ajv, { Schema } from "ajv";
import addFormats from "ajv-formats";
import { ParsedDocumentRow } from "./parse-xlsx";
import { useAsyncRetry } from "react-use";
import { useMemo } from "react";

async function getSchema(issuerId: string, docType: string): Promise<Schema> {
  const ajv = new Ajv();

  const response = await fetch(
    `https://raw.githubusercontent.com/Open-Attestation/oaas-renderer/${paramCase(
      issuerId
    )}/src/templates/${paramCase(issuerId)}/${paramCase(
      docType
    )}/__generated__/${paramCase(docType)}.schema.json`
  );

  const schema = await response.json();

  console.debug(JSON.stringify(schema));

  if (!ajv.validateSchema(schema)) {
    throw new Error("Schema is not valid");
  }

  return schema;
}

export interface ValidationError {
  errors: string[];
  index: number;
}

interface Params {
  jsonSchema: Schema;
  parsedDocumentRows: ParsedDocumentRow[];
}

/**
 * For each parsed document in given list, validate with given json schema
 * @param param0
 * @returns ValidationError[]
 */
export const validateParsedDocuments = ({
  jsonSchema,
  parsedDocumentRows,
}: Params): ValidationError[] => {
  const ajv = new Ajv({
    // By default AJV stops at the first encountered error
    allErrors: true,
    coerceTypes: true,
    $data: true,
  });
  // To make AJV support various formats in the schema
  addFormats(ajv);
  // Define a custom format for MM-YYYY
  ajv.addFormat('MM-YYYY', {
    type: 'string',
    validate: /^(0[1-9]|10|11|12)-\d{4}$/, // Regular expression for MM-YYYY format
  })
  const validate = ajv.compile(jsonSchema);
  const validationErrs: ValidationError[] = [];

  for (let i = 0; i < parsedDocumentRows.length; i += 1) {
    const { rawDocument } = parsedDocumentRows[i];

    validate(rawDocument);
    const metaErrors = validateDocumentMetas([parsedDocumentRows[i]]);

    let errors: string[] = [];
    if (metaErrors.length) {
      errors = metaErrors[0].errors;
    }

    // Accumulate errors into an array
    if (validate.errors) {
      let errorMsgSet = new Set<string>();

      errors = errors.concat(
        validate.errors
          .map(
            ({ message, instancePath }) => `${instancePath} ${message}` ?? ""
          )
          .filter((errMsg) => {
            if (!errorMsgSet.has(errMsg)) {
              errorMsgSet.add(errMsg);
              return true;
            }
            return false;
          })
      );
    }

    if (errors.length) {
      validationErrs.push({
        index: i,
        errors,
      });
    }
  }

  return validationErrs;
};

const regexInvalidFilenameChar = /[<>:"/|?*\\]/;
export function validateDocumentMetas(
  documentRows: ParsedDocumentRow[]
): ValidationError[] {
  const validationErrs: ValidationError[] = [];

  for (let i = 0; i < documentRows.length; i += 1) {
    const { meta } = documentRows[i];

    const errors = validateFilename(meta.oaFilename);

    if (!validateEmail(meta.recipientEmail))
      errors.push("Recipient email is invalid");

    if (errors && errors.length)
      validationErrs.push({
        index: i,
        errors,
      });
  }

  return validationErrs;
}

export function validateFilename(oaFilename: string): string[] {
  const trimmedOaFilename = oaFilename.trim();

  const errors: string[] = [];
  if (!trimmedOaFilename) {
    errors.push("Empty or blank values specified for the oa-filename.");
  }

  if (regexInvalidFilenameChar.test(trimmedOaFilename)) {
    errors.push(
      'Invalid character found in the oa-filename. It should not contain the following characters: <>:"/|?*\\'
    );
  }

  return errors;
}

function validateEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

interface UseValidateParsedDocuments {
  issuerId?: string;
  docType?: string;
  parsedDocuments: ParsedDocumentRow[];
}
export function useValidateParsedDocuments({
  issuerId,
  docType,
  parsedDocuments,
}: UseValidateParsedDocuments) {
  const { value: jsonSchema, loading: getJsonSchemaLoading } =
    useAsyncRetry(async () => {
      if (issuerId && docType) return getSchema(issuerId, docType);
      return undefined;
    }, [issuerId, docType]);

  const validationErrors = useMemo(() => {
    if (jsonSchema && parsedDocuments.length) {
      return validateParsedDocuments({
        jsonSchema,
        parsedDocumentRows: parsedDocuments,
      });
    }

    return [];
  }, [jsonSchema, parsedDocuments]);

  return {
    getJsonSchemaLoading,
    validationErrors,
    jsonSchema,
  };
}
