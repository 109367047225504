import {
  FrameActions,
  FrameConnector,
  HostActionsHandler,
  renderDocument,
} from "@govtechsg/decentralized-renderer-react-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { makeOaDocuments, urlMap } from "./make-document";
import { ParsedDocumentRow } from "./parse-xlsx";
import Selector from "./selector.component";
import Uploader from "./upload.component";
import { useValidateParsedDocuments } from "./useValidateParsedDocuments";

export interface OaDocument {
  name: string;
  document: any;
}

const ListContainer = styled.ul`
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-right: 0.25rem;
  }
  li.selected {
    margin-bottom: -1px;
  }
  .template-label {
    text-decoration: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    display: inline-block;
    background-color: white;
    border-style: solid;
    border-color: #e2e8f0;

    cursor: pointer;
  }
  li.selected .template-label {
    color: #2b6cb0;
    border-bottom: none;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  li .template-label {
    color: #4299e1;
    border-width: 0px;
  }
`;

const SampleLabel = styled.div`
  position: absolute;
  padding: 16px;

  font-size: 72px;
  color: rgba(176, 176, 176, 0.4);

  top: 50%;
  left: 10%;
  transform: translateY(-50%) rotateZ(30deg);

  text-align: center;

  pointer-events: none;
`;

function App() {
  const [toFrame, setToFrame] = useState<HostActionsHandler>();
  const [height, setHeight] = useState(50);
  const [selectedDocument, setSelectedDocument] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [parsedDocuments, setParsedDocuments] = useState<ParsedDocumentRow[]>(
    []
  );
  const [excelFilename, setExcelFilename] = useState<string>("");
  const [issuerId, setIssuerId] = useState<string>("");
  const [docType, setDocType] = useState<string>("");
  const [templates, setTemplates] = useState<{ id: string; label: string }[]>(
    []
  );
  const [source, setSource] = useState("");

  const { getJsonSchemaLoading, validationErrors } = useValidateParsedDocuments(
    {
      issuerId,
      docType,
      parsedDocuments: parsedDocuments,
    }
  );

  const fn = useCallback((toFrame: HostActionsHandler) => {
    // wrap into a function otherwise toFrame function will be executed
    setToFrame(() => toFrame);
  }, []);

  const fromFrame = (action: FrameActions): void => {
    if (action.type === "UPDATE_HEIGHT") {
      setHeight(action.payload);
    }
    if (action.type === "UPDATE_TEMPLATES") {
      setTemplates(action.payload);
      setSelectedTemplate(action.payload[0].id);
    }
  };

  // @ts-ignore
  window.renderDocument = (document) => {
    if (toFrame && document) {
      toFrame({
        type: "RENDER_DOCUMENT",
        payload: { document },
      });
    }
  };

  const oaDocuments = useMemo(
    () =>
      parsedDocuments
        ? makeOaDocuments(issuerId, docType, parsedDocuments)
        : [],
    [parsedDocuments, issuerId, docType]
  );

  useEffect(() => {
    if (toFrame && oaDocuments[selectedDocument]) {
      toFrame({
        type: "RENDER_DOCUMENT",
        payload: { document: oaDocuments[selectedDocument].document },
      });
    }
  }, [toFrame, oaDocuments, selectedDocument]);

  useEffect(() => {
    setSelectedDocument(0);
  }, [parsedDocuments]);

  useEffect(() => {
    if (toFrame && selectedTemplate) {
      toFrame({
        type: "SELECT_TEMPLATE",
        payload: selectedTemplate,
      });
    }
  }, [selectedTemplate, toFrame]);

  return (
    <div style={{ padding: "10px" }}>
      <h1>
        {!excelFilename.length ? "Preview" : `Preview of ${excelFilename}`}
      </h1>
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginRight: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
            }}
          >
            <Uploader
              onParseXlsx={({
                parsedDocumentRows,
                filename,
                issuerId,
                docType,
              }) => {
                setParsedDocuments(parsedDocumentRows);
                setExcelFilename(filename);
                setDocType(docType);
                setIssuerId(issuerId);
                setSource(urlMap[issuerId]);
              }}
            />
          </div>
        </div>
        {getJsonSchemaLoading ? <div>Loading....</div> : undefined}
        <div
          style={{
            width: "100%",
            display: `${
              oaDocuments.length &&
              !validationErrors.length &&
              !getJsonSchemaLoading
                ? "block"
                : "none"
            }`,
          }}
        >
          {parsedDocuments.length && !validationErrors.length ? (
            <Selector
              selected={selectedDocument + 1}
              upperLimit={parsedDocuments.length}
              onSelectionChange={(selected) =>
                setSelectedDocument(selected - 1)
              }
            />
          ) : undefined}
          <ListContainer>
            {templates.map((template) => (
              <li
                key={template.id}
                className={`tab ${
                  selectedTemplate === template.id ? "selected" : ""
                }`}
                onClick={() => setSelectedTemplate(template.id)}
              >
                <div className="template-label">{template.label}</div>
              </li>
            ))}
          </ListContainer>
          <div style={{ position: "relative" }}>
            {parsedDocuments.length && selectedDocument === 0 ? (
              <SampleLabel>
                <b>
                  <i>
                    SPECIMEN
                    <br /> WILL NOT BE ISSUED
                  </i>
                </b>
              </SampleLabel>
            ) : undefined}
            {
              issuerId !== '' && (
                <FrameConnector
                  source={source}
                  dispatch={fromFrame}
                  onConnected={fn}
                  style={{
                    margin: "auto",
                    maxWidth: "1120px",
                    border: "0",
                    width: "100%",
                    height: `${height}px`,
                    padding: "1rem",
                    paddingTop: "0.5rem",
                  }}
                />
              )
            }
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: `${
              oaDocuments.length &&
              validationErrors.length &&
              !getJsonSchemaLoading
                ? "block"
                : "none"
            }`,
          }}
        >
          <h2>Invalid rows in excel sheet =(</h2>
          <p>
            <b>{validationErrors.length}</b> row(s) in the uploaded excel sheet
            were found to contain invalid data.
          </p>
          <div>
            <ul>
              {validationErrors.map(({ index, errors }) => {
                return (
                  <li key={index}>
                    <b>Row {index + 3}</b>
                    <ul>
                      {errors.map((errorMessage) => {
                        return <li key={errorMessage}>{errorMessage}</li>;
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
